<template>
  <div class="box" :style="`${isPersonInfo || isArchives ? 'cursor: pointer;' : ''}`" @click="infoCaback">
    <div
      v-if="hasAvator && imgId"
      class="img_box"
      :style="`width:${size}px;height:${size}px`"
    >
      <img
        :src="`${basePath}/hrsaas-base/staff/getAvatar?onJobId=${imgId}`"
        alt=""
        @error="errFunc"
      >
    </div>
    <div v-else :style="`width:${size}px;height:${size}px;`" class="avater-name">{{ getAvaterName(avaterName) }}</div>
    <div v-if="showUserName" class="user-name" :style="`width: ${width}px; color: ${isPersonInfo || isArchives ? '#5179FB' : '#202a40'}`" :title="tableName">{{ tableName }}</div>
  </div>
</template>

<script>
export default {
  name: 'CopyAvater',
  props: {
    // tableName的宽度
    width: {
      type: [Number, String],
      default: ''
    },
    // 1: 通过onJobId查询头像  2: 通过avatarId查询头像
    type: {
      type: Number,
      default: 1
    },
    avatarId: {
      type: [String, Number],
      default: null
    },
    onJobId: {
      type: [String, Number],
      default: null
    },
    avaterName: {
      type: String,
      default: ''
    },
    staffId: {
      type: [String, Number],
      default: null
    },
    // 图片宽高
    size: {
      type: [Number, String],
      default: 34
    },
    // 显示字数
    defaultNameLength: {
      type: Number,
      default: 2
    },
    // 是否展示员工姓名
    showUserName: {
      type: Boolean,
      default: true
    },
    // 是否跳转到个人信息
    isPersonInfo: {
      type: Boolean,
      default: false
    },
    // true 跳转到社保信息
    isArchives: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      hasAvator: true,
      imgId: undefined,
      basePath: process.env.VUE_APP_API_BASE_URL
    }
  },
  computed: {
    tableName() {
      return this.avaterName
    }
  },
  watch: {
    onJobId(newVal) {
      this.imgId = newVal
      this.hasAvator = true
    },
    avatarId(newVal) {
      this.imgId = newVal
      this.hasAvator = true
    },
    type: {
      handler: function(val) {
        if (val === 1 && this.onJobId) {
          this.imgId = this.onJobId
          this.hasAvator = true
        } else if (val === 2 && this.avatarId) {
          this.imgId = this.avatarId
          this.hasAvator = true
        } else {
          this.hasAvator = false
        }
      },
      immediate: true
    }
  },
  methods: {
    getAvaterName(val) {
      if (!val) {
        return ''
      } else if (val && (val.includes('(已离职)') || val.includes('（已离职）'))) {
        const index = val.indexOf('已离职')
        const avaterNames = val.replace((val.slice(index - 1, index + 7)), '')
        const value = avaterNames.length > 1 ? avaterNames.slice(-this.defaultNameLength) : avaterNames.length === 1 ? avaterNames.substr(-1, 1) : ''
        return value
      }
      return val.length > 1 ? val.slice(-this.defaultNameLength) : val.length === 1 ? val.substr(-1, 1) : ''
    },
    // 跳转个人信息页面
    infoCaback() {
      if (this.isPersonInfo) {
        this.$router.push({
          path: '/staffIndex/userDetail',
          query: {
            staffId: this.staffId ? this.staffId : this.onJobId,
            onJobId: this.onJobId,
            path: encodeURIComponent(this.$route.fullPath)
          }
        })
      }
      if (this.isArchives) {
        this.$router.push({
          path: '/socialSecurity/securityArchivesDetail',
          query: {
            staffId: this.staffId ? this.staffId : this.onJobId,
            path: encodeURIComponent(this.$route.fullPath)
          }
        })
      }
      return false
    },
    errFunc() {
      this.hasAvator = false
    }
  }
}
</script>

<style lang="less" scoped>
.box{
	display: flex;
	align-items: center;
	.img_box{
		border-radius: 50%;
    display: flex;
    img{
			width: 100%;
			height: 100%;
			border-radius: 50%;
		}
	}
	.avater-name{
		flex-shrink: 0;
    background: linear-gradient(180deg,  @sc-primary-60 0%, @sc-primary-100 100%);
    border-radius: 50%;
    color: #fff;
		display: flex;
    align-items: center;
    justify-content: center;
		.font-size(12px);
	}
	.user-name{
    .font-size(14px);
    margin-left: 6px;
    .multi-ellipsis(1);
    white-space: break-spaces;
    word-break: break-all;
    flex: 1;
	}
}
</style>
