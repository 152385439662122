var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass: "box",
      style:
        "" + (_vm.isPersonInfo || _vm.isArchives ? "cursor: pointer;" : ""),
      on: { click: _vm.infoCaback },
    },
    [
      _vm.hasAvator && _vm.imgId
        ? _c(
            "div",
            {
              staticClass: "img_box",
              style: "width:" + _vm.size + "px;height:" + _vm.size + "px",
            },
            [
              _c("img", {
                attrs: {
                  src:
                    _vm.basePath +
                    "/hrsaas-base/staff/getAvatar?onJobId=" +
                    _vm.imgId,
                  alt: "",
                },
                on: { error: _vm.errFunc },
              }),
            ]
          )
        : _c(
            "div",
            {
              staticClass: "avater-name",
              style: "width:" + _vm.size + "px;height:" + _vm.size + "px;",
            },
            [_vm._v(_vm._s(_vm.getAvaterName(_vm.avaterName)))]
          ),
      _vm.showUserName
        ? _c(
            "div",
            {
              staticClass: "user-name",
              style:
                "width: " +
                _vm.width +
                "px; color: " +
                (_vm.isPersonInfo || _vm.isArchives ? "#5179FB" : "#202a40"),
              attrs: { title: _vm.tableName },
            },
            [_vm._v(_vm._s(_vm.tableName))]
          )
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }